import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import icon from "../../assets/Icon.png";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const login = async () => {
    try {
      const response = await axios.post(
        "https://ego2save.purplesea-60d4b659.eastus.azurecontainerapps.io/api/patient/sign-in",
        {
          email: email,
          password: "Ego2025@",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const receivedToken = response.data.token;

      localStorage.setItem("authToken", receivedToken);

      return receivedToken;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      return null;
    }
  };

  const handleContinue = async () => {
    const getToken = await login();
    if (getToken) {
      navigate("/selfie");
    }
  };

  return (
    <div className="checkin-container">
      <div className="checkin-header">
        <div className="icon-wrapper">
          <img src={icon} alt="User Icon" className="user-icon" />
        </div>
      </div>
      <div className="checkin-content">
        <p>Insira seu e-mail para continuar</p>
        <input
          type="email"
          placeholder="Insira seu email"
          className="input-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="continue-button" onClick={handleContinue}>
          Continuar
        </button>
      </div>
      <footer className="checkin-footer">
        <p>
          Powered by <span>EGO</span>
        </p>
      </footer>
    </div>
  );
}

export default LoginPage;
